<script lang="ts" setup>
import {
  searchScopeOptions,
  type ISearchPropertyLeasePortfolioRequest,
  type ILeaseReportTemplate,
} from '@portfolio'
import { useLeaseFilters } from './composables/useLeaseFilters'
import type { FormKitNode } from '@formkit/core'
const { unitSize } = useDivisions()

type Props = {
  visible: boolean
  modelValue: ISearchPropertyLeasePortfolioRequest
  template?: ILeaseReportTemplate
  showAdvancedFilter?: boolean
}
// PROPS
const props = withDefaults(defineProps<Props>(), {
  showAdvancedFilter: true,
  visible: false,
  modelValue: () => ({}),
  template: undefined,
})

// EMITS
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void
  (e: 'update:modelValue', value: ISearchPropertyLeasePortfolioRequest): void
  (e: 'close'): void
}>()

// REFS
const visible = useVModel(props, 'visible', emit)

// FILTERS
const {
  statuses,
  states,
  sectors,
  fields,
  isFetchingStatuses,
  isFetchingSectors,
  isFetchingStates,
  isFetchingFields,
} = useLeaseFilters(toRefs(props))

// AUTH
const { isLandlord, isTenant } = useAuth()

// FORMATTERS
const formatAdvancedSearchText = (values: string[] | undefined) =>
  (values ?? [])
    .map((value) => {
      const text =
        fields.value.find((f) => f.value === value)?.label ?? title(value)
      return `"${text}"`
    })
    .join(' and ')

// FORM
const onInput = (value: object) => {
  const parsedValue = parseFilters(value)
  if (JSON.stringify(props.modelValue) !== JSON.stringify(parsedValue)) {
    emit('update:modelValue', parsedValue)
  }
}

// STICKY TITLE
const sidebarModal = ref<HTMLElement | null>(null)

watch(
  () => props.visible,
  (value) => {
    if (value) {
      setTimeout(() => {
        sidebarModal.value = document.querySelector('.sidebar-modal')
      }, 500)
    }
  },
  { immediate: true },
)

const removeAdvancedSearchMode = (node: FormKitNode) => {
  node.on('input', ({ payload }) => {
    if (!payload || payload?.length === 0) {
      node.parent?.input({ fieldIds: payload, mode: undefined })
    } else {
      node.parent?.input({
        fieldIds: payload,
        mode: node.parent?.value?.mode ?? 'hasFields',
      })
    }
  })
}
</script>

<template>
  <SidebarOverlay v-model:visible="visible">
    <FormKit
      v-slot="{ value }"
      :value="modelValue"
      type="form"
      :actions="false"
      class="relative"
      @input="onInput"
    >
      <!-- Header -->
      <header
        class="pt-top-2 dark:bg-gray-850 standalone:pt-safe-top sticky top-0 z-10 -mx-6 mb-4 flex items-center justify-between bg-white px-6 pb-2 shadow-lg transition-all"
      >
        <h3 class="font-medium text-white">Filters</h3>

        <Button
          color="opacitySecondary"
          class="mt-2 h-8 w-8 px-0 py-0"
          icon="close"
          @click="emit('close')"
        >
        </Button>
      </header>

      <!-- Filter -->
      <FormKit
        id="LeaseName"
        label="Lease name"
        placeholder="Lease name"
        outer-class="flex-grow mb-4"
        type="text"
        name="searchLeaseName"
      />

      <FormKit
        id="Address"
        label="Address"
        placeholder="Address"
        outer-class="flex-grow mb-4"
        type="text"
        name="searchPropertyAddress"
      />

      <FormKit
        label="Type"
        placeholder="Type"
        outer-class="flex-grow mb-4"
        type="listbox"
        name="scope"
        :options="searchScopeOptions"
      />

      <!-- TODO: Select -->
      <FormKit
        label="Status"
        placeholder="Status"
        outer-class="flex-grow mb-4"
        type="listbox"
        name="statuses"
        :options="statuses"
        :multiple="true"
        :loading="isFetchingStatuses"
      />

      <!-- Landlord -->
      <FormKit
        v-if="isLandlord"
        label="Lessee"
        placeholder="Lessee"
        outer-class="flex-grow mb-4"
        type="text"
        name="searchLessee"
      />

      <!-- Tenant -->
      <FormKit
        v-else-if="isTenant"
        label="Lessor"
        placeholder="Lessor"
        outer-class="flex-grow mb-4"
        type="text"
        name="searchLessor"
      />

      <!-- TODO: Select -->
      <FormKit
        label="Sector"
        placeholder="Sector"
        outer-class="flex-grow mb-4"
        type="listbox"
        name="sectors"
        :options="sectors"
        :multiple="true"
        :loading="isFetchingSectors"
      />

      <FormKit type="group" outer-class="flex flex-row" name="sizeRange">
        <label
          for="sizeRangeMin"
          class="formkit-label formkit-invalid:text-danger mb-2 block text-sm font-medium text-gray-900 transition duration-100 dark:text-white"
        >
          Area ({{ unitSize?.text }})
        </label>
        <div class="flex justify-end gap-4">
          <FormKit
            id="sizeRangeMin"
            placeholder="Min"
            outer-class="w-1/2 mb-4 self-end"
            type="number"
            name="min"
            :min="0"
            :max="Number(modelValue.sizeRange?.max ?? Infinity) - 1"
            :validation="[
              ['max', Number(modelValue.sizeRange?.max ?? Infinity) - 1],
            ]"
          />
          <FormKit
            placeholder="Max"
            outer-class="w-1/2 mb-4"
            type="number"
            name="max"
            :min="Number(modelValue.sizeRange?.min ?? 0) + 1"
            :validation="[['min', Number(modelValue.sizeRange?.min ?? 0) + 1]]"
          />
        </div>
      </FormKit>

      <FormKit
        label="Lessee trading name"
        placeholder="Lessee trading name"
        outer-class="flex-grow mb-4"
        type="text"
        name="searchTradingName"
      />

      <!-- TODO: Select -->
      <FormKit
        label="State"
        placeholder="State"
        outer-class="flex-grow mb-4"
        type="listbox"
        name="states"
        :options="states"
        :multiple="true"
        :loading="isFetchingStates"
      />

      <FormKit
        type="group"
        outer-class="flex flex-row"
        name="commencementDateRange"
      >
        <label
          for="commencementDateRangeFrom"
          class="formkit-label formkit-invalid:text-danger mb-2 block text-sm font-medium text-gray-900 transition duration-100 dark:text-white"
        >
          Commencement date
        </label>
        <div class="flex gap-4">
          <FormKit
            id="commencementDateRangeFrom"
            placeholder="Start Date"
            outer-class="w-1/2 mb-4"
            type="datepicker"
            name="from"
            pattern="\d{2}/\d{2}/\d{4}"
          />
          <FormKit
            placeholder="End date"
            outer-class="w-1/2 mb-4 self-end"
            type="datepicker"
            name="to"
            pattern="\d{2}/\d{2}/\d{4}"
          />
        </div>
      </FormKit>

      <FormKit type="group" outer-class="flex flex-row" name="expiryDateRange">
        <!--  eslint-disable-next-line vuejs-accessibility/label-has-for -->
        <label
          for="expiryDateRangeFrom"
          class="formkit-label formkit-invalid:text-danger mb-2 block text-sm font-medium text-gray-900 transition duration-100 dark:text-white"
        >
          Expire date
        </label>
        <div class="flex gap-4">
          <FormKit
            id="expiryDateRangeFrom"
            placeholder="Start Date"
            outer-class="w-1/2 mb-4"
            type="datepicker"
            name="from"
            pattern="\d{2}/\d{2}/\d{4}"
          />
          <FormKit
            placeholder="Expire date"
            outer-class="w-1/2 mb-4 self-end"
            type="datepicker"
            name="to"
            pattern="\d{2}/\d{2}/\d{4}"
          />
        </div>
      </FormKit>

      <FormKit type="group" outer-class="flex flex-row" name="publishedAtRange">
        <!--  eslint-disable-next-line vuejs-accessibility/label-has-for -->
        <label
          for="publishedAtRangeFrom"
          class="formkit-label formkit-invalid:text-danger mb-2 block text-sm font-medium text-gray-900 transition duration-100 dark:text-white"
        >
          Created at
        </label>
        <div class="flex gap-4">
          <FormKit
            id="publishedAtRangeFrom"
            placeholder="From"
            outer-class="w-1/2 mb-4"
            type="datepicker"
            name="from"
            pattern="\d{2}/\d{2}/\d{4}"
            :max-date="new Date()"
            prevent-min-max-navigation
          />
          <FormKit
            placeholder="To"
            outer-class="w-1/2 mb-4 self-end"
            type="datepicker"
            name="to"
            pattern="\d{2}/\d{2}/\d{4}"
            :max-date="new Date()"
            prevent-min-max-navigation
          />
        </div>
      </FormKit>

      <FormKit type="group" outer-class="flex flex-row" name="updatedAtRange">
        <!--  eslint-disable-next-line vuejs-accessibility/label-has-for -->
        <label
          for="updatedAtRangeFrom"
          class="formkit-label formkit-invalid:text-danger mb-2 block text-sm font-medium text-gray-900 transition duration-100 dark:text-white"
        >
          Updated at
        </label>
        <div class="flex gap-4">
          <FormKit
            id="updatedAtRangeFrom"
            placeholder="From"
            outer-class="w-1/2 mb-4"
            type="datepicker"
            name="from"
            pattern="\d{2}/\d{2}/\d{4}"
            :max-date="new Date()"
            prevent-min-max-navigation
          />
          <FormKit
            placeholder="To"
            outer-class="w-1/2 mb-4 self-end"
            type="datepicker"
            name="to"
            pattern="\d{2}/\d{2}/\d{4}"
            :max-date="new Date()"
            prevent-min-max-navigation
          />
        </div>
      </FormKit>

      <template v-if="showAdvancedFilter">
        <h3
          class="bg-gray-850/50 -mx-6 mb-2 mt-4 px-6 py-2 font-medium text-white"
        >
          Advanced Filter
        </h3>

        <FormKit type="group" name="advanced" outer-class="mb-3">
          <FormKit
            label="Filter by field"
            placeholder="Filter by"
            outer-class="flex-grow mb-4"
            type="listbox"
            name="fieldIds"
            :searchable="true"
            :multiple="true"
            :plugins="[removeAdvancedSearchMode]"
            :options="fields"
            :loading="isFetchingFields"
          />

          <FormKit
            :disabled="
              !value?.advanced?.fieldIds ||
              value?.advanced?.fieldIds?.length === 0
            "
            :label="`Only leases that contains ${formatAdvancedSearchText(
              value?.advanced?.fieldIds,
            )}`"
            outer-class="flex-grow "
            type="checkbox"
            name="mode"
            on-value="hasFields"
            off-value="missingFields"
          />
        </FormKit>
        <span
          class="mb-4 inline-flex gap-2 text-sm font-normal text-gray-500 dark:text-gray-300"
        >
          <Icon name="info" class="ml-1 mt-1 h-4 w-4" />
          <span>
            <template v-if="value?.advanced?.mode === 'hasFields'">
              By enabling, it will only show leases that
              <Tooltip
                content="The field must have value and be approved"
                placement="top"
                class="min-w-max bg-gray-950 text-xs"
              >
                <span
                  class="text-primary-50/80 font-bold underline decoration-dotted"
                >
                  contains
                </span>
              </Tooltip>
              the value you entered in the field above.
            </template>
            <template v-else>
              By disabling, it will only show leases that
              <Tooltip
                content="The field is not approved or N/A"
                placement="top"
                class="w-max bg-gray-950 text-xs"
              >
                <span class="font-bold underline decoration-dotted">
                  does not contain
                </span>
              </Tooltip>
              the value you entered in the field above.
            </template>
          </span>
        </span>
      </template>
    </FormKit>

    <div class="flex justify-end">
      <Button @click="emit('close')"> Apply </Button>
    </div>
  </SidebarOverlay>
</template>
